<template>
    <div ref="player" id="player"></div>
</template>

<script>
import * as videoObject from './course-play';
export default {
    name: 'videoPlay',
    props: {
        params: {
            type: Object,
            default: () => {}
        },
        width: {
            type: [String, Number],
            default: '100%',
        },
        height: {
            type: [String, Number],
            default: '',
        },
        // 视频片头片尾广告
        adMatter:{
            type:Array,
            default:()=>[]
        },
        // 封面图
        bgImg:{
            type: String,
            default: '',
        }
    },
    data(){
        return {
          
        }
    },
    watch:{
        params:{
            handler(val){
                videoObject.init('#player',val,this.width,this.height,this.adMatter, this.bgImg);
            },
            immediate:true,
        }
    },
    mounted() {
    },
    created(){
        this.timer = setInterval(() => {
            this.$emit('getCurrentTime', videoObject.getCurrentTime())
        }, 1000);
    },
    methods: {
        seekVideo (time) {
            videoObject.seekVideo(time)
        }
    },
    destroyed() {
        videoObject.destroy();
        clearInterval(this.timer);
    }
}
</script>