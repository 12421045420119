<template>
    <div class="wrapper video-wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="2" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container">
                <!-- 面包屑 -->
                <div class="flex pb5 x-left mobile-bread">
                    <div class="color999">您所在的位置：</div>
                    <a-breadcrumb class="flex x-left">
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item>
                            <router-link class="color999" :to="{ path: '/course/index?type=1' }">精品课堂</router-link>
                        </a-breadcrumb-item>
                        <a-breadcrumb-item style="max-width:960px;" class="row-1">{{videoInfo.title}}</a-breadcrumb-item>
                    </a-breadcrumb>
                </div>
            </div>
            <!-- 视频信息 -->
            <div class="video-play container flex">
                <div class="video-outer-box">
                    <course-play ref="videoPlayRef" @getCurrentTime="(value) => $refs?.transcriptListRef?.getCurrentTime(value)" v-if="videoInfo.sign" :params="videoInfo" :bgImg="videoInfo.default_pic_url | urlFilter(750)">
                    </course-play>
                    <!-- 权限提示 -->
                    <div v-else class="no-vid-box" @click="handleVideoAuthEvent">
                        <div class="no-vid-img">
                            <img :src="videoInfo.default_pic_url | urlFilter(750)" alt="">
                        </div>
                        <div class="video-auth-box flex-column x-center y-center">
                            <i class="med med-bofang"></i>
                            <div v-if="videoAuth.msg" class="fs16 colorfff mt10">{{ videoAuth.msg }}</div>
                            <a-button v-if="videoAuth.btnText" class="mt15 fs16" style="border-color:#fff;color:#fff;background:transparent;">{{videoAuth.btnText}}</a-button>
                        </div>
                    </div>
                </div>
                <div class="video-detail flex-column x-between">
                    <a-tabs style="width:100%;" default-active-key="1">
                        <a-tab-pane key="1" tab="讲者信息">
                             <!-- 个人信息 -->
                            <div class="video-content-name pl20 pr20 flex y-center">
                                <div class="flex y-center" style="cursor: pointer;" @click="expertJumpDetail(videoInfo.user_id)">
                                    <img class="img" :src="videoInfo.avatar | urlFilter(250)" alt="">
                                    <div class="flex-column ml15" style="max-width:180px;">
                                        <p class="t-l row-1">
                                            <span class="strong fs16 color333">{{ videoInfo.real_name }}</span>
                                            <span class="ml10 color999 fs12">{{ videoInfo.department }}</span>
                                        </p>
                                        <p class="t-l mt5 color666 fs12 row-1">{{ videoInfo.org_cnname }}</p>
                                    </div>
                                </div>
                                <div class="attention-btn flex x-center y-center cursor" @click="getFollowExperts(videoInfo.user_id,true)" v-if="videoInfo.fans_status == 0">
                                    <i class="med med-jiahao"></i>
                                    <span class="fs12 strong colorfff ml5">关注</span>
                                </div>
                                <div class="isattention-btn flex x-center y-center cursor" @click="getFollowExperts(videoInfo.user_id)" v-if="videoInfo.fans_status == 1 || videoInfo.fans_status == 2">
                                    <span class="fs12 strong color999 ml5">已关注</span>
                                </div>
                            </div>
                            <!-- 粉丝数等 -->
                            <div style="height:80px;" class="video-content-name flex x-around pl10 pr10 y-center">
                                <div class="flex-column">
                                    <span class="strong color333 fs18">{{ videoInfo.video_num || 0 }}</span>
                                    <span class="fs12 color333">视频</span>
                                </div>
                                <div class="line"></div>
                                <div class="flex-column">
                                    <span class="strong color333 fs18">{{ videoInfo.abstract_num || 0 }}</span>
                                    <span class="fs12 color333">论文</span>
                                </div>
                                <div class="line"></div>
                                <div class="flex-column">
                                    <span class="strong color333 fs18">{{ videoInfo.fans_count || 0 }}</span>
                                    <span class="fs12 color333">粉丝</span>
                                </div>
                            </div>
                            <!-- 个人介绍 -->
                            <div v-if="videoInfo.intro" class="video-content-name content-desc t-l">
                                <div class="pt10 pb20 pl20 pr20" ref="resumeContent" v-html="videoInfo.intro"></div>
                            </div>
                            <div v-else class="video-content-name content-desc pt20 pb20 pl20 pr20 t-l color999">暂无介绍
                            </div>
                            <!-- 关键词 -->
                            <div class="content-subject flex x-left y-center">
                                <template v-if="videoInfo.keyword">
                                        <span class="content-subject-item colorfff fs12 t-l" :class="{ 'ml10': index != 0 }" v-for="(item, index) in strToArr(videoInfo.keyword)" :key="index">{{ item
                                            }}</span>
                                </template>
                            </div>
                            <!-- 浏览量等 -->
                            <div class="page-view flex y-center">
                                <div>
                                    <i class="med med-yanjing"></i>
                                    <span class="fs12 color999 ml5">{{ videoInfo.hits }}人</span>
                                </div>
                                <div class="color999 fs12">
                                    <span class="cursor" @click="getVideoLikeCollect">
                                        <i :class="['med', videoInfo.is_collect == 1 ? 'med-shoucang1' : 'med-shoucang']"></i>
                                        <span class="ml5">{{ videoInfo.is_collect == 1 ? '已收藏' : '收藏' }}</span>
                                    </span>
                                    <!-- <span class="ml15 cursor" @click="getVideoLikeCollect('1')">
                                        <i :class="['med', videoInfo.zan == 1 ? 'med-dianzan' : 'med-dianzan_huaban']"></i>
                                        <span class="ml5">{{ videoInfo.zan == 1 ? '已赞' : '点赞' }}</span>
                                    </span> -->
                                    <!-- <span class="ml15 cursor">
                                        <img src="~@/assets/images/transpond.png" alt="">
                                        <span class="ml5">转发</span>
                                    </span> -->
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="文字稿">
                            <transcript-list ref="transcriptListRef" @seekVideo="(value) => $refs.videoPlayRef.seekVideo(value)"></transcript-list>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
            <!-- 相关视频 -->
            <div class="container flex about-video" style="padding:20px 20px 0 20px;">
                <div class="video-other" :class="{ 'video-other-left': activeKey == 4 }">
                    <a-tabs v-model="activeKey" :animated="false" @change="handleTabChangeEvent">
                        <a-tab-pane key="1" tab="相关视频" class="mt10">
                            <a-spin :spinning="loading" tip="Loading...">
                                <div class="container" style="min-height:200px;">
                                    <course-item width="216px" height="124px" :lineCount="5" :data-list="relatedList" type="2" :targetType="1" @clickEvent="handleOtherVideoEvent"></course-item>
                                    <a-empty v-if="!relatedList.length && !loading" />
                                </div>
                            </a-spin>
                            <div class="t-c" v-if="relatedTotal > 0">
                                <a-pagination :total="relatedTotal" v-model="relatedParams.page" :page-size="relatedParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                            </div>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="作者相关" force-render>
                           <a-spin :spinning="loading" tip="Loading...">
                                <div class="container" style="min-height:200px;">
                                    <course-item width="216px" height="124px" :lineCount="5" :data-list="userRelatedList" type="2" :targetType="1" @clickEvent="handleOtherVideoEvent"></course-item>
                                    <a-empty v-if="!userRelatedList.length && !loading" />
                                </div>
                            </a-spin>
                            <div class="t-c" v-if="userRelatedTotal > 0">
                                <a-pagination :total="userRelatedTotal" v-model="userRelatedParams.page" :page-size="userRelatedParams.limit" show-quick-jumper @change="handleUserPageChangeEvent" :hideOnSinglePage="true" />
                            </div>
                        </a-tab-pane>
                        <!-- <a-tab-pane key="3" tab="相关指南">
                            <a-spin :spinning="loading" tip="Loading...">
                             <div class="container" style="min-height:200px;">
                                相关指南
                             </div>
                            </a-spin>
                        </a-tab-pane> -->
                        <a-tab-pane key="4" tab="视频评论">
                            <a-spin :spinning="loading" tip="Loading...">
                                <div class="" style="min-height:200px;">
                                    <comment-list :list="commentList" :commentCount="commentCount" :pageSize="commentParams.limit" @pageEvent="commentPageChangeEvent($event)" @handleTextChange="handleTextChange($event)"></comment-list>
                                </div>
                            </a-spin>
                        </a-tab-pane>
                        
                    </a-tabs>
                </div>
                <div v-if="activeKey == 4" class="about-meeting" style="width:33.33%;">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <buy-modal ref="buyModal"></buy-modal>
    </div>
</template>

<script>
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import layoutRight from '@/components/layout/layout-right';
import coursePlay from '@/components/course-player/course-play.vue';
import courseItem from '@/components/courser-list-item';
import commentList from '@/components/comment-list.vue';
import textItem from '@/components/text-item.vue';
import { videoLogo, mediaNavList } from '@/config/const';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { expertJumpDetail } from '@/utils/jumpPageMethods';
import storage from 'store'
import { mapState } from 'vuex';
import { setWeixinShare } from "@/utils/wechatShare";
import buyModal from '@/components/buy-modal.vue';
import transcriptList from '@/components/transcript-list.vue'
export default {
    name: "CourseInfoDetail",
    components: {
        transcriptList,
        commonTitle,
        Header,
        Footer,
        layoutRight,
        coursePlay,
        courseItem,
        commentList,
        textItem,
        buyModal
    },
    data() {
        return {
            video_id: this.$route.query.id, // 视频id
            type: this.$route.query.type || 1, // 1为收费视频  2为系列课
            loading: true,
            activeKey: '1',
            logoUrl: videoLogo,
            navList: mediaNavList, //顶部菜单

            //视频相关
            relatedTotal: 0,  // 总数
            relatedList: [],  // 当前页数据
            __relatedList:[], // 所有数据
            relatedParams: {
                page: 1,
                limit: 10
            },

            //用户相关视频
            userRelatedTotal: 0,
            userRelatedList: [],
            userRelatedParams: {
                page: 1,
                limit: 10
            },

            //视频评论
            commentList: [], //视频评论
            commentCount: 0, //评论总数
            commentParams: {
                page: 1,
                limit: 10,
            }, //视频评论列表参数


            // 视频权限
            videoAuth: {
                code: '', // code类型
                msg: '', // 权限提示
            },
            videoInfo: {}, //视频详情


            layoutRightConfig: {
                correlationMeet: { //相关会议
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 3,
                        subject_ids: ''
                    }, // 参数条件
                },
            },
        }
    },
    watch:{
        '$route.query'(query){
            console.log(query);
            this.video_id = query.id; // 视频id
            this.type = query.type || 1; // 1为收费视频  2为系列课
            this.pageInit();
            this.getRelatedVideo();
        },
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        expertJumpDetail,
        // 选项卡切换
        handleTabChangeEvent(key) {
            if (key == 2) {
                this.getUserRelatedVideo();
            }
            if (key == 3) {

            }
            if (key == 4 && this.commentList.length == 0) {
                this.getCommentList();
            }

        },
        // 获取视频详情
        getVideoInfo() {
            return this.request.get('getPremiumVideoInfo', { video_id: this.video_id }, { hideModal: true })
        },
        //视频权限跳转
        handleVideoAuthEvent() {
            // 需要收费，跳转到收费页面
            if (this.videoAuth.code == 201) {
                this.$refs.buyModal.show(this.videoInfo,1);
            }else if(this.videoAuth.code == 401){
                window.location.replace("https://www.sciconf.cn/unified/login?return_url=" + encodeURIComponent(window.location.href))
            }
        },
        // 视频相关
        getRelatedVideo() {
            this.request.get('getRelatedVideo', { video_id: this.video_id, ...this.relatedParams }).then(res => {
                let list = res.data;
                list.forEach(it => {
                    it.img = it.default_pic_url;
                    it.video_count && (it.courseNum = it.video_count + '节');
                    if(it.power_type == 1){
                        it.price = '免费';
                    }
                });
                this.relatedTotal = list.length
                this.__relatedList = list
                this.relatedList = list.slice(0,this.relatedParams.limit);
            })
        },
        // 视频相关切换页码
        handlePageChangeEvent(page) {
            // this.relatedParams.page = page
            this.relatedList = this.__relatedList.slice((page - 1)*this.relatedParams.limit,page*this.relatedParams.limit);
        },
        // 用户相关视频
        getUserRelatedVideo() {
            this.request.get('getUserVideoList', { video_id: this.video_id, ...this.userRelatedParams }).then(res => {
                let { list, count } = res.data;
                list.forEach(it => {
                    it.img = it.default_pic_url;
                    it.video_count && (it.courseNum = it.video_count + '节');
                    if(it.power_type == 1){
                        it.price = '免费';
                    }
                });
                this.userRelatedTotal = +count
                this.userRelatedList = list
            })
        },
        // 用户相关视频切换页码
        handleUserPageChangeEvent(page) {
            this.relatedParams.page = page
            this.getUserRelatedVideo();
        },
        // 用户相关与视频相关跳转
        handleOtherVideoEvent(item) {
            this.$router.push({ path: '/course/play?id=' + item.id });
        },
        // 视频评论
        getCommentList() {
            this.loading = true
            // id为课程id type // 1 收费视频 2 系列课 
            this.commentParams.type_id = this.video_id;
            this.commentParams.type = this.type == 1 ? 6 : 7;
            this.request.get('getOnlineCommentList', this.commentParams).then(res => {
                this.commentCount = parseInt(res.data.count)
                this.commentList = res.data.list
                this.loading = false
            })
        },
        // 视频评论切换页码
        commentPageChangeEvent(e) {
            this.commentParams.page = e
            this.getCommentList()
        },
        // 发表评论
        handleTextChange(e) {
            let data = {
                type: this.type,
                content: e,
                type_id: this.video_id
            }
            this.request.get('addOnlineComment', data).then(res => {
                this.commentParams.page = 1;
                this.getCommentList()
            })
        },
        //关注
        getFollowExperts(userid, is_focus) {
            this.request.post('FollowExperts', { experts_uid: userid }).then(res => {
                if (res.data.code == 200) {
                    this.videoInfo.fans_status = this.videoInfo.fans_status == 0 ? 1 : 0
                }
                //关注医生添加积分,只有关注的时候才加积分
                if (is_focus) {
                    this.request.post('AddPoint', { task_id: 3, type_id: userid }, { hideModal: true }).then(res => {}).catch(err => {})
                }

            })
        },
        // 收藏/点赞
        getVideoLikeCollect() {
            this.request.get('addOnlineCollect', { type: this.type, type_id: this.video_id }).then(res => {
                this.videoInfo.is_collect = !this.videoInfo.is_collect;
            })
        },
        pageInit() {
            let requestArr = [
                this.getVideoInfo(), //视频信息
            ]
            Promise.all(requestArr).then(res => {
                let { code, msg, data } = res[0];
                this.videoInfo = data;
                // 设置分享
                let share_title = data.share_title || data.title;
                let share_desc = data.share_desc || (data.intro && data.intro.substr(0,30));
                setWeixinShare(share_title,share_desc,data.default_pic_url)

                this.loading = false

                // 判断是否登陆
                if(!this.userInfo.user_id){
                    this.videoAuth = {
                        code:401,
                        msg:'您暂未登陆，请登陆后观看',
                        btnText:'立即登陆'
                    }
                    return;
                }

                // 判断是否为非200业务
                if (code != 200) {
                    this.videoAuth = {
                        code,
                        msg,
                        btnText: code == 201 ? '立即购买' : ''
                    }
                }
            })
        },
        // 关键词分割
        strToArr(str) {
            return str.split('|')
        },
    },
    created() {
        this.pageInit();
        this.getRelatedVideo();
    }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/course-play.less');
</style>