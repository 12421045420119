<template>
    <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">

        <!-- Background of PhotoSwipe.
	         It's a separate element, as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>

        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">

            <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
            <div class="pswp__container">
                <!-- don't modify these 3 pswp__item elements, data is added later on -->
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>

            <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">

                <div class="pswp__top-bar">

                    <!--  Controls are self-explanatory. Order can be changed. -->

                    <div class="pswp__counter"></div>

                    <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>

                    <button class="pswp__button pswp__button--share" title="Share"></button>

                    <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>

                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>

                    <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                            <div class="pswp__preloader__cut">
                                <div class="pswp__preloader__donut"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div>
                </div>

                <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                </button>

                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                </button>

                <div class="pswp__caption flex x-between">
                    <div class="pswp__caption__center"></div>
                    <a-button :ghost="true" :loading="maxImageLoading" style="margin-right:30px;" :disabled="isMaxImage" id="tap_max_src">{{maxImageLoading ? '原图加载中...' : (!isMaxImage ? '查看原图' : '已加载原图')}}</a-button>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import './../assets/lib/photoswipe/css/default-skin/default-skin.css';
import './../assets/lib/photoswipe/css/photoswipe.css';
import {
    openPhotoSwipe
} from './../assets/lib/photoswipe/js/photoswipe-init.js';

let gallery = null;
let isBindTap = false;
export default {
    props: {
        picturelist: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: '1'
        }

    },
    data() {
        return {
			isMaxImage:false,
			maxImageLoading:false,
        }
    },
    computed: {

    },
    components: {

    },
    watch: {
    },
    methods: {

        // 格式化数据
        formatDataResult() {
            let tmpPictureList_list = JSON.parse(JSON.stringify(this.picturelist));
            let result = [];
            let len = tmpPictureList_list.length;
            for (let key = 0; key < len; key++) {
                let item = tmpPictureList_list[key];
                result.push({
                    msrc: item.image_url + '!wx750',
                    src: item.image_url + '!wx750',
                    w: item.width || 750,
                    h: item.height || 500,
                    title: item.title || this.title,
                })
            }
            return result;
        },

        /* 查看原图 */
        uploadImgSize(that) {
			if(this.isMaxImage){
				return false;
			}
			this.maxImageLoading = true;
            var currentIndex = gallery.getCurrentIndex();
			let src = gallery.items[currentIndex].src.replace('!wx750', '');
			let image = new Image();
			image.onload = (e) => {
				let {naturalHeight,naturalWidth} = e.target;
				gallery.items[currentIndex].src = src;
				gallery.items[currentIndex].w = naturalWidth;
				gallery.items[currentIndex].h = naturalHeight;
				gallery.invalidateCurrItems();
				gallery.updateSize(true);
				this.isMaxImage = true;
				this.maxImageLoading = false;
			}
			image.onerror = (e) => {
				 this.$message.error('大图加载失败');
				 this.isMaxImage = true
				 this.maxImageLoading = false;
			}
			image.src = src;
			
            return false;
        },

        /* 获取图片相关信息 */
        getCurrentImageInfo() {
			var currentItem = gallery.currItem;
			var isMaxImage = true
            if (currentItem.src.indexOf('!wx750') != -1) {
				isMaxImage = false
            }
			this.isMaxImage = isMaxImage
		},
		hasClass(ele, cls) {
			cls = cls || '';
			if (cls.replace(/\s/g, '').length == 0) return false; //当cls没有参数时，返回false
			return new RegExp(' ' + cls + ' ').test(' ' + ele.className + ' ');
		},
		
		addClass(ele, cls) {
			if (!this.hasClass(ele, cls)) {
				ele.className = ele.className == '' ? cls : ele.className + ' ' + cls;
			}
		},

		removeClass(ele, cls) {
			if (this.hasClass(ele, cls)) {
				var newClass = ' ' + ele.className.replace(/[\t\r\n]/g, '') + ' ';
				while (newClass.indexOf(' ' + cls + ' ') >= 0) {
					newClass = newClass.replace(' ' + cls + ' ', ' ');
				}
				ele.className = newClass.replace(/^\s+|\s+$/g, '');
			}
		},

        /* 显示图片查看器 */
        showPhowoswipe(index) {
            gallery = openPhotoSwipe(this.formatDataResult(), {
                index: parseInt(index)
            });

            // 阻止多次事件绑定
			//if (isBindTap) return false;
            gallery.framework.bind(gallery.scrollWrap /* bind on any element of gallery */, 'pswpTap', (e) => {
                //isBindTap = true;
                e.stopPropagation();

                /* 目标元素ID */
                var eventId = e.detail.target.id;
                switch (eventId) {
                    /* 点击查看大图 */
                    case 'tap_max_src':
                        this.uploadImgSize(e.target);
                        break;
                    default:
                        console.log(e);
                        console.log('无效事件');
                };

            });

            /* 监听改变后事件 */
            gallery.listen('afterChange', () => {
				this.getCurrentImageInfo();
				
				// 添加动画
				// let imgElem = gallery.currItem.container.getElementsByClassName('pswp__img');
				// let imgElemLen = imgElem.length;
				// imgElem = imgElem[imgElemLen - 1];
				// 进入动画
				// this.addClass(imgElem, 'animated animate__lightSpeedInRight');
				// setTimeout(() => {
				// 	this.removeClass(imgElem, 'animated animate__lightSpeedInRight');
				// }, 900);
            });

            /* 监听关闭事件 */
            gallery.listen('close', () => {
            });
        },

    },
    mounted() { }

}
</script>
<style lang="less">
.pswp{
	z-index:999
}
.pswp__caption {
    text-align: center;
}

.pswp__caption__center {
    min-height: auto;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 1.2;
    padding-right: 15px;
    max-width: 100%;
}

.pswp__big__src {
    border: 1px solid #fff;
    padding: 5px 10px;
    border-radius: 15px;
    display: inline-block;
    color: #fff;
    font-size: 10px;
}

#tap_max_src[disabled]{
	color:#fff;
	opacity: 0.5;
}


.animated {
    -webkit-animation-duration: 0.9s;
	animation-duration: 0.9s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes lightSpeedInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%,0,0) skewX(-30deg);
        transform: translate3d(100%,0,0) skewX(-30deg)
    }

    60% {
        opacity: 1;
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg)
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes lightSpeedInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%,0,0) skewX(-30deg);
        transform: translate3d(100%,0,0) skewX(-30deg)
    }

    60% {
        opacity: 1;
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg)
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

.animate__lightSpeedInRight {
    -webkit-animation-name: lightSpeedInRight;
    animation-name: lightSpeedInRight;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}

</style>
