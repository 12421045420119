<template>
    <div class="layout-right" style="margin-top:-30px;">
        <div v-for="(itemModule, key) in config" :key="key">
            <!-- 院士风采 -->
            <template v-if="key == 'expert' && config.expert.show">
                <common-title class="mt30" path="/" :title="config.expert.title"></common-title>
                <router-link v-for="(item, index) in 2" :key="index" :class="{ 'mt20': index != 0 }"
                    class="personal-item flex y-center x-left" :to="{ path: '/' }">
                    <img class="personal-item-image"
                        src="https://files.sciconf.cn/medcon/2020/06/20200601/2020060111574383671051429.jpg" alt="">
                    <div class="ml15 flex-item">
                        <div class="color999 row-1"><span class="color333 fs16">付小兵</span> <span
                                class="fs12">中国人民解放军第四医学中心</span></div>
                        <div class="color666 mt5 row-2 fs12">中国医学工程师医学工程师医学工程师医学工程师医学医学工程师医学工程师中国医学工程程医学工程师</div>
                    </div>
                </router-link>
            </template>
            <!-- 本月直播 -->
            <template v-if="key == 'live' && config.live.show && dataList.live.length">
                <common-title class="mt30" :path="config.live.moreLink || '/live-list'"
                    :title="config.live.title" :type="config.live.type || ''"></common-title>
                <template v-for="(item, index) in dataList.live">
                    <live-item type="horizontal" :item="item"></live-item>
                </template>
            </template>
            <!-- 精品课堂 -->
            <template v-if="key == 'course' && config.course.show">
                <common-title class="mt30" path="/" :title="config.course.title" :type="config.course.type || ''"></common-title>
                <across-media-item
                    :item="{ id: 1, title: '测试标题', desc: '测试标题测试标题测试标题测试标题测试标题测试标题测试标题', url: '/', img: 'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg' }">
                    <div slot="bottom" class="fs14 color999">
                        <span>课时：</span>
                        <span class="color333">12小时30分钟</span>
                    </div>
                </across-media-item>
            </template>
            <!-- 有奖调查 -->
            <template v-if="key == 'survey' && config.survey.show">
                <common-title class="mt30" path="/" :title="config.survey.title" :type="config.survey.type || ''"></common-title>
                <survey-item width="400px"
                    :item="{ id: 1, url: '/', title: '子宫内膜癌保育治疗探索子宫内膜癌保育治疗探索', img: 'https://files.sciconf.cn/medcon/2022/01/20220105/2022010509180794857136210.jpg' }">
                </survey-item>
            </template>
            <!-- 新闻资讯 -->
            <template v-if="key == 'news' && config.news.show && dataList.news.length">
                <common-title class="mt30" :path="config.news.moreLink || '/infomation-list?index=115'"
                    :title="config.news.title" :type="config.news.type || ''"></common-title>
                <div class="zhanpin-list">
                    <template v-for="(item, index) in dataList.news">
                        <text-item :showDesc="false" :key="index" gap="10" type="3"
                            :path="config.news.detailLink || '/infomation-detail'" :item="item"
                            source="layoutRight"></text-item>
                    </template>
                </div>
            </template>
            <!-- 推荐视频 -->
            <template v-if="key == 'video' && config.video.show && dataList.video.length">
                <common-title class="mt30" :path="config.video.moreLink || '/video-list'" :title="config.video.title" :type="config.video.type || ''"></common-title>
                <div class="flex list-box flex-wrap" style="cursor:pointer;">
                    <div class="list-box-item" v-for="(item, index) in dataList.video" :key="index">
                        <vertical-media-item style="margin-left: 0;" :id="item.id" :jumpType="config.video.type || ''" width="189px" :item="item"
                            @clickEvent="videoJumpDetail(item.id,item.listId,config.video.type||'',$route.params.id)"></vertical-media-item>
                    </div>
                </div>
            </template>
            <!-- 会议 -->
            <template v-if="key == 'meeting' && config.meeting.show && dataList.meeting.length">
                <common-title class="mt30" :path="config.meeting.moreLink || '/meeting-list'"
                    :title="config.meeting.title" :type="config.meeting.type || ''"></common-title>
                <div v-for="(item, index) in dataList.meeting" :key="index" @click="meetJumpDetail(item.href, item.id,item.meeting_id,config.meeting.type)">
                    <across-media-item :item="item" type="layoutRight">
                        <template v-slot:bottom>
                            <div>
                                <div class="row-1" v-if="item.start_time">
                                    <i class="med med-31shijian"></i>
                                    <span class="color999 fs14 ml5">{{ item.start_time.slice(0, 10) }}</span>
                                </div>
                                <div class="row-1" v-if="item.address">
                                    <i class="med med--didian"></i>
                                    <span class="color999 fs14 ml5">{{ item.address }}</span>
                                </div>
                            </div>
                        </template>
                    </across-media-item>
                </div>
            </template>
            <!-- 推荐论文 -->
            <template v-if="key == 'paper' && config.paper.show && dataList.paper.length">
                <common-title class="mt30" :title="config.paper.title"
                    :path="config.paper.moreLink || '/meetpaper-list'" :type="config.paper.type || ''"></common-title>
                <template v-for="(item, index) in dataList.paper">
                    <across-media-item :key="index" :item="item" @navigateTo="goPaperList">
                        <template v-slot:bottom>
                            <div class="fs12 color999 row-1">
                                <span v-if="item.start_time">{{ item.start_time.slice(0, 10) }}
                                    <span v-if="item.end_time"> 至 {{ item.end_time.slice(0, 10) }}</span>
                                </span>
                            </div>
                        </template>
                    </across-media-item>
                </template>
            </template>
            <!-- 指南解读 -->
            <template v-if="key == 'guide' && config.guide.show && dataList.guide.length">
                <common-title class="mt30" :isMore="true" :path="config.guide.moreLink || '/video-list'"
                    :title="config.guide.title" :type="config.guide.type || ''"></common-title>
                <div class="list-box flex flex-wrap" style="cursor:pointer;">
                    <div class="list-box-item list-box-guide" v-for="(item, index) in dataList.guide" :key="index">
                        <vertical-media-item :item="item" width="189px"
                            :class="['mobile-ml0', { 'ml0': (index + 1) % 2 == 1 }]"
                            @clickEvent="videoJumpDetail(item.video_list[0].id)" />
                    </div>
                </div>
            </template>
            <!-- 视频排名 -->
            <template v-if="key == 'videoRank' && config.videoRank.show">
                <common-title class="mt30" :title="config.videoRank.title" :type="config.videoRank.type || ''"></common-title>
                <div class="mt10">
                    <div class="paper-writing y-center mb15" v-for="(item, index) in 8" :key="index">
                        <img v-if="index == 0" src="~@/assets/images/journal-skill1.png" alt="">
                        <img v-if="index == 1" src="~@/assets/images/journal-skill2.png" alt="">
                        <img v-if="index == 2" src="~@/assets/images/journal-skill3.png" alt="">
                        <img v-if="index > 2" src="~@/assets/images/video-sort-grey.png" alt="">
                        <span class="ml10 fs16 color333 row-1" :class="{ 'color666': index > 2 }">本周视频热播榜排名</span>
                    </div>
                </div>
            </template>
            <!-- 推荐期刊 -->
            <template v-if="key == 'periodicalRank' && config.periodicalRank.show && dataList.periodicalRank.length">
                <common-title class="mt30" :title="config.periodicalRank.title" :path="config.periodicalRank.moreLink || '/periodical-list'" :type="config.periodicalRank.type || ''"></common-title>
                <div class="mt10">
                    <div class="flex flex-wrap" v-for="(item, index) in dataList.periodicalRank" :key="index">
                        <journal-item width="130px" height="163px" :path="config.periodicalRank.detailLink || '/periodical-detail'" fontSize="16px"
                            :journalItem="item"></journal-item>
                    </div>
                </div>
            </template>
            <!-- 临床指南下载 -->
            <template v-if="key == 'guideDownload' && config.guideDownload.show && dataList.guideDownload.length">
                <common-title class="mt30" :path="config.guideDownload.moreLink || '/guide-list'" :title="config.guideDownload.title" :type="config.guideDownload.type || ''"></common-title>
                <div class="zhanpin-list">
                    <template v-for="(item, index) in dataList.guideDownload">
                        <text-item :key="index" :path="config.guideDownload.detailLink || '/guide-list-detail'" gap="10" type="3"
                            :item="item" source="layoutRight" :showDesc="config.guideDownload.type == 'ma' ? true : false"></text-item>
                    </template>
                </div>
            </template>
            <!-- 专题广告位 -->
            <template v-if="key == 'lbList' && config.lbList.show && dataList.lbList.length">
                <a-carousel v-if="dataList.lbList.length" class="carousel" arrows autoplay :autoplay-speed="4000" style="overflow: hidden;">
                    <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                        <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right-circle" />
                    </div>
                    <img style="cursor:pointer;" :src="item.image_url | urlFilter(360)" alt="" @touchstart="handleBannerLink(item)" @click="handleBannerLink(item)" v-for="(item, index) in dataList.lbList" :key="index">
                </a-carousel>
            </template>
            <!-- 相关会议 -->
            <template v-if="key == 'correlationMeet' && config.correlationMeet.show && dataList.correlationMeet.length">
                <common-title class="mt30" :title="config.correlationMeet.title"
                    :path="config.correlationMeet.moreLink || '/meeting-list'" :type="config.correlationMeet.type || ''"></common-title>
                <div v-for="(item, index) in dataList.correlationMeet" :key="index"
                    @click="meetJumpDetail(item.href, item.id,'','')">
                    <across-media-item :item="item" type="layoutRight">
                        <template v-slot:bottom>
                            <div>
                                <div class="row-1" v-if="item.report_time">
                                    <i class="med med-31shijian"></i>
                                    <span class="color999 fs14 ml5">{{ item.report_time }}</span>
                                </div>
                                <div class="row-1" v-if="item.address">
                                    <i class="med med--didian"></i>
                                    <span class="color999 fs14 ml5">{{ item.address }}</span>
                                </div>
                            </div>
                        </template>
                    </across-media-item>
                </div>
            </template>
            <!-- 相关直播 -->
            <template v-if="key == 'correlationLive' && config.correlationLive.show && dataList.correlationLive.length">
                <common-title class="mt30" :path="config.correlationLive.moreLink || '/live-list'"
                    :title="config.correlationLive.title" :type="config.correlationLive.type || ''"></common-title>
                <div v-for="(item, index) in dataList.correlationLive" :key="index"
                    @click="videoJumpDetail(item.video_list[0].id)">
                    <live-item type="horizontal" :key="index" :item="item"></live-item>
                </div>
            </template>
            <!-- 相关直播 -->
            <template v-if="key == 'correlationLives' && config.correlationLives.show && dataList.correlationLives.length">
                <common-title class="mt30" :path="config.correlationLives.moreLink || '/live-list'"
                    :title="config.correlationLives.title" :type="config.correlationLives.type || ''"></common-title>
                <div v-for="(item, index) in dataList.correlationLives" :key="index"
                    @click="videoJumpDetail(item.video_list[0].id)">
                    <live-item type="horizontal" :key="index" :item="item"></live-item>
                </div>
            </template>
            <!-- 相关视频 -->
            <template v-if="key == 'correlationVideo' && config.correlationVideo.show && dataList.correlationVideo.length">
                <common-title class="mt30" :path="config.correlationVideo.moreLink || '/video-list'"
                    :title="config.correlationVideo.title" :type="config.correlationVideo.type || ''"></common-title>
                <div class="flex list-box flex-wrap" style="cursor:pointer;">
                    <div class="list-box-item" v-for="(item, index) in dataList.correlationVideo" :key="index">
                        <vertical-media-item style="margin-left: 0;" :id="item.id" width="189px" :jumpType="config.correlationVideo.type || ''" :item="item"
                            @clickEvent="videoJumpDetail(item.id,'',config.correlationVideo.type || '')">
                        </vertical-media-item>
                    </div>
                </div>
            </template>
            <!-- 相关指南-->
            <template v-if="key == 'correlationNews' && config.correlationNews.show && dataList.correlationNews.length">
                <common-title class="mt30" :path="config.correlationNews.moreLink || '/guide-list'"
                    :title="config.correlationNews.title" :type="config.correlationNews.type || ''"></common-title>
                <div class="zhanpin-list">
                    <template v-for="(item, index) in dataList.correlationNews">
                        <text-item path="/guide-list-detail" :showDesc="false" :key="index" gap="10" type="3"
                            :item="item"></text-item>
                    </template>
                </div>
            </template>
            <!-- 相关资讯 -->
            <template v-if="key == 'correlationInfo' && config.correlationInfo.show && dataList.correlationInfo.length">
                <common-title class="mt30" :path="config.correlationInfo.moreLink || '/infomation-list'"
                    :title="config.correlationInfo.title" :type="config.correlationInfo.type || ''"></common-title>
                <div class="zhanpin-list correlationInfo">
                    <template v-if="config.correlationInfo.limit == 1">
                        <div v-for="(item, index) in dataList.correlationInfo" :key="index" class="hot-topic-box"
                        :class="{ 'mt20': index != 0 }" style="margin-top:10px;">
                            <hot-topic-item v-if="item.information_type" containerWidth="100%" widthNew="160px" :showBottom="item.news_pic&&!(item.images && item.images.length) ? false : true" :path="config.correlationInfo.detailLink || '/infomation-detail'" :type="item.type" :item="item"  :width="item.information_type == 2 ? '125px':'189px'" class="bb">
                                <div slot="report-bottom" v-if="item.news_pic">
                                    <div class="flex x-left">
                                        <span class="color999 fs14">{{item.create_time}}</span>
                                    </div>
                                    <div class="flex x-between">
                                        <span v-if="item.source" style="max-width:calc(100% - 55px);" class="color999 fs14 row-1 t-l">{{item.source}}</span>
                                        <span class="color999 fs14">{{item.browsing}}阅读</span>
                                    </div>
                                </div>
                            </hot-topic-item>
                        </div>
                    </template>
                    <template v-for="(item, index) in dataList.correlationInfo" v-else-if="config.correlationInfo.limit == 3">
                        <text-item path="/guide-list-detail" :showDesc="false" :key="index" gap="10" type="3" :item="item" source="layoutRight"></text-item>
                    </template>
                    <div  v-else class="list-box flex flex-wrap" style="cursor:pointer;">
                        <div class="list-box-item list-box-guide" v-for="(item, index) in  dataList.correlationInfo" :key="index">
                            <vertical-media-item :item="item" width="189px"
                                :class="['mobile-ml0', { 'ml0': (index + 1) % 2 == 1 }]"
                                @clickEvent="videoJumpDetail(item.id)" />
                        </div>
                    </div>
                </div>
            </template>
            <!-- 会议回放 -->
            <template v-if="key == 'orgMeetback' && config.orgMeetback.show && dataList.orgMeetback.length">
                <common-title class="mt30" :title="config.orgMeetback.title"
                    :path="config.orgMeetback.moreLink || '/organization/org-playback'" :type="config.orgMeetback.type || ''"></common-title>
                <template v-for="(item, index) in dataList.orgMeetback">
                    <across-media-item :item="item" type="layoutRight" :key="index" @navigateTo="handleNavigateEvent(item)">
                        <template v-slot:bottom>
                            <div>
                                <div class="row-1" v-if="item.end_time">
                                    <i class="med med-31shijian"></i>
                                    <span class="color999 fs14 ml5">{{ item.end_time }}</span>
                                </div>
                            </div>
                        </template>
                    </across-media-item>
                </template>
            </template>
            <!-- 会议相册 -->
            <template v-if="key == 'meetingAlbum' && config.meetingAlbum.show && dataList.meetingAlbum.length">
                <common-title class="mt30" :path="config.meetingAlbum.moreLink || '/organization/org-album'"
                    :title="config.meetingAlbum.title" :type="config.meetingAlbum.type || ''"></common-title>
                <album-item :data-list="dataList.meetingAlbum" lineCount="1"></album-item>
            </template>
            <!-- 会议论文相关 -->
            <template v-if="key == 'relatedArticle' && config.relatedArticle.show && dataList.relatedArticle">
                <!-- 相关文章 -->
                <div v-if="dataList.relatedArticle.related_abstract.length" class="mb30">
                    <common-title :title="config.relatedArticle.title[0]" :isMore="false" :type="config.relatedArticle.type || ''"></common-title>
                    <template v-for="(item, index) in dataList.relatedArticle.related_abstract">
                        <div :title="item.title || item.title_en" v-if="item.title || item.title_en" :key="index"
                            class="row-1 fs16 t-l article-name pb5 pt5" style="line-height:30px;"
                            @click="articleDetail(item.id)">
                            {{ item.title || item.title_en }}
                        </div>
                    </template>
                </div>
                <!-- 相关类别 -->
                <div v-if="dataList.relatedArticle.related_class.length" class="mb30">
                    <common-title :title="config.relatedArticle.title[1]" :isMore="false" :type="config.relatedArticle.type || ''"></common-title>
                    <template v-for="(it, idx) in dataList.relatedArticle.related_class">
                        <div :title="it.title || it.title_en" v-if="it.title || it.title_en" :key="idx"
                            class="row-1 fs16 t-l article-name pb5 pt5" style="line-height:30px;"
                            @click="articleDetail(it.id)">
                            {{ it.title || it.title_en }}
                        </div>
                    </template>
                </div>
                <!-- 相关壁报 -->
                <div v-if="dataList.relatedArticle.related_paper.length" class="mb30">
                    <common-title :title="config.relatedArticle.title[2]" :isMore="false" :type="config.relatedArticle.type || ''"></common-title>
                    <template v-for="(itPaper, idex) in dataList.relatedArticle.related_paper">
                        <div :title="itPaper.title || itPaper.title_en" v-if="itPaper.title" :key="idex"
                            @click="handlePreviewEvent(dataList.relatedArticle.related_paper.filter((it) => it.title != ''), 0)"
                            class="row-1 fs16 t-l paper-btn article-name pb5 pt5" style="line-height:30px;">
                            {{ itPaper.title }}
                            <img class="paper-img" @load="handleLoadEvent($event, idex, 'related_paper')"
                                :src="itPaper.imgpath | urlFilter(750)" alt="" />
                        </div>
                    </template>
                </div>
                <!-- 相关视频 -->
                <div v-if="dataList.relatedArticle.related_video.length">
                    <common-title class="mt30" :title="config.relatedArticle.title[3]" :isMore="false" :type="config.relatedArticle.type || ''"></common-title>
                    <div class="flex list-box flex-wrap" style="cursor:pointer;">
                        <div class="list-box-item" v-for="(itVideo, idxVideo) in dataList.relatedArticle.related_video"
                            :key="idxVideo">
                            <vertical-media-item style="margin-left: 0;" width="189px" :item="itVideo"
                                @clickEvent="videoJumpDetail(itVideo.video_id)">
                            </vertical-media-item>
                        </div>
                    </div>
                </div>
            </template>
            <!-- 推荐大咖 -->
            <template v-if="key == 'recommendExpert' && config.recommendExpert.show && dataList.recommendExpert.length">
                <div style="margin-top:30px;">
                    <common-title :path="config.recommendExpert.moreLink || '/special-ma/expert-list'" title="推荐大咖" :type="config.recommendExpert.type || ''"></common-title>
                    <template v-for="(recommendItem,recommendIndex) in dataList.recommendExpert">
                        <recommend-expert-item :item="recommendItem" :key="recommendIndex"></recommend-expert-item>
                    </template>
                    
                </div>
            </template>
        </div>
        <photoswipe ref="photoswipe" :picturelist="picList"></photoswipe>
    </div>
</template>
<script>
import commonTitle from '@/components/common-title';
import hotTopicItem from '@/components/hot-topic-item';
import textItem from '@/components/text-item';
import liveItem from '@/components/live-item.vue';
import acrossMediaItem from '@/components/across-media-item';
import verticalMediaItem from '@/components/vertical-media-item';
import surveyItem from '@/components/survey-item';
import photoswipe from '@/components/photoswipe.vue';
import journalItem from '@/components/journal-item';
import albumItem from '@/components/album-item';
import recommendExpertItem from '@/components/recommend-expert-item';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { isObject } from '@/utils/judgeDataType';
import { videoJumpDetail } from '@/utils/jumpPageMethods'
import { config } from 'process';
import { committee_id,front_url } from '@/config';
export default {
    name: "layoutRight",
    props: {
        config: {
            type: Object,
            default: () => {
                return {};
                // 专家模块
                // expert:{
                //     show:true, // 是否显示
                //     limit:2,   // 显示条数
                //     title:'院士风采', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 直播
                // live:{
                //     show:true, // 是否显示
                //     limit:1,   // 显示条数
                //     title:'本月直播', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 课堂
                // course:{
                //     show:true, // 是否显示
                //     limit:1,   // 显示条数
                //     title:'精品课堂', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 调查
                // survey:{
                //     show:true, // 是否显示
                //     limit:1,   // 显示条数
                //     title:'有奖调查', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 新闻
                // news:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'求职招聘', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 视频
                // video:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'推荐视频', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 会议
                // meeting:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'推荐会议', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 论文
                // paper:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'推荐论文', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 指南
                // guide:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'指南解读', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 视频热播排名
                // videoRank:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'视频热播排名', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 推荐期刊
                // periodicalRank:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'推荐期刊', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },
                // // 临床指南下载
                // guideDownload:{
                //     show:true, // 是否显示
                //     limit:3,   // 显示条数
                //     title:'临床指南下载', // 标题
                //     data:{},   // 参数条件
                //     moreLink:'', // 更多的链接
                // },

            }
        }
    },
    data() {
        return {
            // 图片查看器数据
            picList: [],
            reqArr: {
                live: 'GetLiveList', //本月直播 
                meeting: 'GetMeetingList', // 本月会议
                periodicalRank: 'PeriodicalList', //推荐期刊
                news: 'GetNews', //新闻资讯
                paper: 'AbstractMeeting', //会议论文
                guide: 'GetCategoryVideoList', //指南解读
                video: this.config?.video?.type == 'ma' ? 'GetVideoListNew':'GetVideoNew', //最新回放视频
                guideDownload: 'GuideList', //临床指南下载
                correlationMeet: 'GetRecommendMeetings', //相关会议
                correlationLive: 'GetRecommendedLive', //相关直播
                correlationLives: 'GetRecommendLives', //相关直播新
                correlationVideo: 'GetRecommendVideos', //相关视频
                correlationNews: 'GetRecommendGuides', //相关指南
                correlationInfo: 'GetNews', //相关资讯
                orgMeetback: 'GetOpenVideoMeetingLive', //机构号会议回放
                meetingAlbum: 'MeetingPictureList', //机构号会议相册
                relatedArticle: 'GetRelated', //会议论文详情相关内容
                recommendExpert:'ExpertsListV3', //专题页-专家列表
                lbList:"TopicImgList", //专题页-轮播图
            },
            dataList: {
                meeting: [],  //本月会议
                live: [], //本月直播
                periodicalRank: [], //推荐期刊
                news: [], //新闻资讯
                paper: [], //会议论文
                guide: [], //指南解读
                video: [], //最新回放视频
                guideDownload: [], //临床指南下载
                correlationMeet: [], //相关会议
                correlationLive: [], //相关直播
                correlationLives: [], //相关直播新
                correlationVideo: [], //相关视频
                correlationNews: [], //相关指南
                correlationInfo: [], //相关资讯
                orgMeetback: [], //机构号会议回放列表
                meetingAlbum: [], //机构号会议相册
                relatedArticle: {
                    related_abstract: [],
                    related_class: [],
                    related_paper: [],
                    related_video: [],
                },
                recommendExpert:[], //推荐大咖列表
                lbList:[], //轮播列表
            }
        };
    },
    watch: {
        config: {
            handler: function (value) {
                if(!value){
                    return false;
                }
                for (let key in value) {
                    // 判断是否存在请求接口
                    if (key == 'correlationInfo') {
                        this.reqArr['correlationInfo'] = value['correlationInfo']?.limit == 1 ? 'GetNews' : value['correlationInfo']?.limit == 2 ? 'GuideRelatedVideo' : 'GetRecommendGuidesV2'
                    }
                    let methods = value[key].methods || 'get'
                    let url = this.reqArr[key]
                    if (url) {
                        // 资讯详情相关资讯/指南根据条件判断
                        this.request[methods](url, value[key].data).then(res => {
                            switch (key) {
                                case 'meeting':
                                    let obj = {
                                        title: 'cn_title',
                                        img: 'pic_url'
                                    }
                                    this.dataList[key] = jsonFormat(res.data.data.slice(-3), obj)
                                    return;
                                case 'periodicalRank':
                                    let periObj = {
                                        desc: 'en_name',
                                    }
                                    this.dataList[key] = jsonFormat(res.data.list, periObj)
                                    return;
                                case 'paper':
                                    let paperObj = {
                                        title: 'cn_title',
                                        img: 'pic_url'
                                    }
                                    this.dataList[key] = jsonFormat(res.data.list, paperObj)
                                    return;
                                case 'guide':
                                    let data = res.data.video_list.list
                                    data.forEach(item => {
                                        item.title = item.video_list[0].title
                                        item.img = item.video_list[0].default_pic_url
                                    })
                                    let guideObj = {
                                        name: 'author',
                                        org: 'org_cnname'
                                    }
                                    this.dataList[key] = jsonFormat(data, guideObj)
                                    return;
                                case 'video':
                                    let videoObj = {
                                        img: 'default_pic_url',
                                        name: 'author',
                                        org: 'author_org'
                                    }
                                    if(this.config.video.type == 'ma'){
                                        videoObj['org'] = 'org_cnname'
                                    }
                                    this.dataList[key] = jsonFormat(value['video'].type == 'ma' ? res.data.list : res.data, videoObj)
                                    return;
                                case 'guideDownload':
                                    let guideDownObj = {
                                        listId:'id',
                                        news_title: 'title_cn',
                                        id: 'guide_id'
                                    }
                                    // if(this.config.guideDownload.type == 'ma'){
                                    //     guideDownObj['create_time'] = 'publish_date'
                                    //     guideDownObj['source'] = 'reference'
                                    //     guideDownObj['id'] = 'g_guide_id'
                                    // }
                                    this.dataList[key] = jsonFormat(res.data.list, guideDownObj)
                                    return;
                                case 'correlationMeet':
                                    let correlationObj = {
                                        title: 'cn_title',
                                        img: 'pic_url'
                                    }
                                    this.dataList[key] = jsonFormat(res.data.data, correlationObj)
                                    return;
                                case 'correlationLive':
                                    this.dataList[key] = res.data
                                    return;
                                case 'correlationVideo':
                                    let dataVideo = res.data.list
                                    let videocoObj = {
                                        img: 'default_pic_url',
                                        name: 'author',
                                        org: ''
                                    }
                                    dataVideo.forEach(item => {
                                        if(item.author_org){
                                            videocoObj['org'] = 'author_org'
                                        }else{
                                            videocoObj['org'] = 'org_cnname'
                                        }
                                    })
                                    
                                    this.dataList[key] = jsonFormat(res.data.list, videocoObj)
                                    return;
                                case 'correlationInfo':
                                    if(value['correlationInfo'].limit == 3){
                                        let newsObj
                                        if(res.data.list[0].guide_id){
                                            newsObj = {
                                                news_title: 'title_cn',
                                                id: 'guide_id'
                                            }
                                        }else{
                                            newsObj = {
                                                news_title: 'title',
                                                name:'real_name'
                                            }
                                        }
                                        this.dataList['correlationInfo'] = jsonFormat(res.data.list, newsObj)
                                    }
                                    if(value['correlationInfo'].limit == 4){
                                        let newsObj = {
                                            img: 'default_pic_url',
                                            name:'author',
                                            org:'org_cnname'
                                        }
                                        this.dataList['correlationInfo'] = jsonFormat(res.data.list, newsObj)
                                    }
                                    if(value['correlationInfo'].limit == 2){
                                        let videocoObj = {
                                            img: 'default_pic_url',
                                            name: 'author',
                                            org: 'author_org'
                                        }
                                        this.dataList['correlationInfo'] = jsonFormat(res.data.list, videocoObj)
                                    }
                                    if(value['correlationInfo'].limit == 1){
                                        this.dataList[key] = res.data.list
                                    }
                                    return;
                                case 'correlationNews':
                                    let newsObj = {
                                        news_title: 'title_cn',
                                        id: 'guide_id'
                                    }
                                    this.dataList[key] = jsonFormat(res.data.list, newsObj)
                                    return;
                                case 'orgMeetback':
                                    let meetbackObj = {
                                        news_title: 'title',
                                        id: 'type_id',
                                        img: 'card_img'
                                    }
                                    this.dataList[key] = jsonFormat(res.data.list, meetbackObj)
                                    return;
                                case 'meetingAlbum':
                                    this.dataList[key] = res.data.slice(0, 1)
                                    return;
                                case 'relatedArticle':
                                    // 相关文章
                                    for (let key in res.data) {
                                        if (isObject(res.data[key])) {
                                            let arr = []
                                            arr.push(res.data[key])
                                            res.data[key] = arr
                                        }
                                        if (key != 'related_video') {
                                            res.data[key] = res.data[key].slice(0, 5)
                                        }
                                        if (key == 'related_video') {
                                            let videoObj = {
                                                img: 'video_img',
                                                name: 'real_name',
                                                org: 'org_cnname'
                                            }
                                            res.data[key] = jsonFormat(res.data[key].slice(0, 4), videoObj)
                                        }
                                    }
                                    this.dataList[key] = res.data
                                    return;
                                case 'lbList':
                                    this.dataList[key] = res.data
                                    return;
                                default:
                                    this.dataList[key] = res.data.list
                                    return;
                            }
                        })
                    }

                }
            },
            immediate: true,
            deep: true

        }
    },
    components: {
        commonTitle,
        hotTopicItem,
        textItem,
        liveItem,
        acrossMediaItem,
        surveyItem,
        verticalMediaItem,
        journalItem,
        albumItem,
        photoswipe,
        recommendExpertItem,
    },

    computed: {},

    methods: {
        videoJumpDetail,
        isObject,
        handleBannerLink(item){
            item.skip_url &&  window.open(item.skip_url,'_blank')
        },
        meetJumpDetail(href, id,meeting_id,type){
            if(type == 'ma'){
                this.request.post('TopicMeetingDetail',{
                    topic_id:this.$route.params.id,
                    id:id
                },{ hideModal: true }).then(res=>{})
            }
            let link_id = type == 'ma' ? meeting_id : id
            let url = href ? href : front_url+'cn/web/index/' + link_id
            
            window.open(url, '_blank')
        },
        articleDetail(id) {
            this.$emit('articleDetail', id)
        },
        goPaperList(item) {
            this.$router.push({
                path: '/article-list',
                query: {
                    id: item.id
                }
            })
        },
        handleNavigateEvent(item) {
            this.$router.push({
                path: '/special-detail',
                query: {
                    type_id: item.type_id,
                    type: 1
                }
            })
        },
        // 读取图片高度
        handleLoadEvent(e, index, type) {
            if (type == 'related_paper') {
                let item = this.dataList.relatedArticle.related_paper[index];
                this.$set(item, 'height', e.target.naturalHeight)
                this.$set(item, 'image_url', item.imgpath)
                console.log(this.dataList.relatedArticle.related_paper[index])
            }
        },
        // 查看图片
        handlePreviewEvent(dataList, index) {
            this.picList = dataList;
            this.$nextTick(() => {
                this.$refs.photoswipe.showPhowoswipe(index, this.picList);
            })
        },
    },
    mounted() {
        // console.log(this.reqArr)
    }
}

</script>
<style lang='less' scoped>
.paper-writing {
    height: 24px;
    display: flex;
    cursor: pointer;
}

.ml0 {
    margin-left: 0;
}

.list-box {

    /deep/.list-box-item {
        &:nth-of-type(1),
        &:nth-of-type(2) {
            .vertical-media-item {
                margin-top: 0;
            }
        }
    }
}

.article-name {
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px dashed #d8d8d8;
    }
}
.home-right {
        .carousel{
            height: 200px;
            img{
                width: 100%;
                height: 200px;
                display: block;
                object-fit: cover;
            }
            .slick-dots-bottom{
                bottom: 20px;
            }
        }
    }
.paper-btn {
    cursor: pointer;
    position: relative;
    display: inline-block;

    .paper-img {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
    }
}


@media screen and (max-width:768px) {
    .list-box {
        &-item {
            .vertical-media-item {
                width: 100% !important;
            }

            width: calc(33% - 10px);

            .mobile-ml0 {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width:768px) and (min-width:550px) {
    .list-box {
        &-item {
            &:nth-of-type(3) {
                .vertical-media-item {
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width:550px) {
    .list-box {
        &-item {
            width: calc(50% - 10px);
        }
    }
}</style>