export const getUrlParams = (url) => {
    // 通过 ? 分割获取后面的参数字符串
    url = url.replace(/\"|\'/g,'');
    url = url.split('?');
    if(url.length <= 1){
        return {};
    }
    let urlStr = url[1]
    // 创建空对象存储参数
    let obj = {};
    // 再通过 & 将每一个参数单独分割出来
    let paramsArr = urlStr.split('&')
    for(let i = 0,len = paramsArr.length;i < len;i++){
        // 再通过 = 将每一个参数分割为 key:value 的形式
        let arr = paramsArr[i].split('=')
        obj[arr[0]] = arr[1];
    }
    return obj
}
//节流方法
export function throttle(cb,duration){
    let startTime = Date.now();
    let timer = null;
    return function(){
        let nowTime = Date.now();
        let remainning = duration - (nowTime - startTime);
        clearTimeout(timer);
        if(remainning <= 0){
            startTime = Date.now();
            cb.apply(this,arguments);
        }else{
            timer = setTimeout(() => {
                cb.apply(this,arguments);
            },remainning)
        }
    }
}

//防抖函数
export function debounce(cb,duration){
    let timer = null;
    return function(){
        if(timer){
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            timer = null;
            cb.apply(this,arguments);
        },duration);
    }
}

// 关键字高亮方法
export function hightLight (keyWord, suggtion, color) {
    // 使用 regexp 构造函数，因为这里要匹配的是一个变量
    const reg = new RegExp(keyWord, 'ig')
    const newSrt = String(suggtion).replace(reg, function (p) {
      return `<span style="color:${color};">${p}</span>`
    })
    return newSrt
}