module.exports = {
    /********************* 专家导航模块 *************************/ 
    exportLogo:'images/logo.png',
    expertNavList : [[
        {
            name : '首页',
            path : '/',
        },
        {
            name : '大咖访谈',
            path : '/infomation-list?index=6'
        },
        {
            name : '专家风采',
            path : '/expert/index'
        },
        {
            name : '院士风采',
            path : '/expert/ys-mien'
        },
        {
            name : '学科主委',
            path : '/expert/committee'
        },
        {
            name : '省市学会任职库',
            path : '/job-warehouse/index'
        },
        {
            name : '我的关注',
            path : '/expert/follow'
        }
    ]],// 专家风采导航菜单地址
    // expertNavList : [[
    //     {
    //         name : '首页',
    //         path : '/',
    //     },
    //     {
    //         name : '专家风采',
    //         path : '/expert/index'
    //     },
    //     {
    //         name : '院士风采',
    //         path : '/expert/ys-mien'
    //     },
    //     {
    //         name : '学科主委',
    //         path : '/expert/committee'
    //     },
    //     {
    //         name : '我的关注',
    //         path : '/expert/follow'
    //     }
    // ]],// 专家风采导航菜单地址
    /********************* 资源导航模块 *************************/ 
    courseLogo : 'images/excellent-course-logo.png',
    liveLogo:'images/logo.png',
    videoLogo:'images/logo.png',
    mediaNavList:[[
        {
            name : '首页',
            path : '/',
        },
        {
            name : '会议直播',
            path : '/live-list'
        },
        {
            name: '视频回放',
            path : '/video-class'
        },
        {
            name: '专题视频',
            path : '/special-list'
        },
        {
            name: '精品课堂',
            path: '/course/index',
            children:[
                {
                    name:"精品课程",
                    path:"/course/index?type=1",
                },
                {
                    name:"专题课程",
                    path:"/course/index?type=2",
                }
            ]
        }
    ]],//精品课堂导航菜单
    /********************机构号*************************/
    organizationLogo:'images/logo.png',
    organizationList : [

    ], //机构号导航菜单
    /********************机构号详情导航模块*************************/
    orgDetailList : (committee_id) => {
        return [[
            {
                name : '主页',
                path : '/organization/org-homepage?committee_id='+committee_id,
            },
            {
                name : '资讯',
                path : '/organization/org-infomation?committee_id='+committee_id,
            },
            {
                name: '会议',
                path : '/organization/org-meeting?committee_id='+committee_id,
            },
            {
                name: '直播',
                path : '/organization/org-live?committee_id='+committee_id,
            },
            {
                name: '回放',
                path : '/organization/org-playback?committee_id='+committee_id,
            },
            {
                name: '相册',
                path : '/organization/org-album?committee_id='+committee_id,
            },
        ]]
    }, //机构号详情导航菜单

    // 企业号导航菜单
    companyNavList:(id) =>{
        return [[
            {
                name : '主页',
                path : '/company/index?company_id='+id,
            },
            {
                name : '资讯',
                path : '/company/news?company_id='+id,
            },
            {
                name : '产品',
                path : '/company/product?company_id='+id,
            },
            {
                name : '会议',
                path : '/company/meeting?company_id='+id,
            },
            {
                name : '直播',
                path : '/company/live?company_id='+id,
            },
            {
                name : '视频',
                path : '/company/video?company_id='+id,
            },
            {
                name : '招聘',
                path : '/company/invite?company_id='+id,
            },
        ]]
    }
}