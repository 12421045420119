<!-- 文字稿 -->
<template>
    <div class="transcript-content" @touchmove="handleTouchmovetEvent">
        <template v-if="transcriptList.length > 0">
            <a-input-search v-model="searchVal" class="mb10" placeholder="请输入关键字" @change="handleSearchEvent"/>
            <div class="transcript" :style="{ height: height }" ref="transcriptContentRef" @wheel="handleWheelEvent">
                <div  class="transcript-item mb10" v-for="(item, index) in transcriptList" :key="index" @click="(event) => handleToTimeEvent(event, item, index)">
                    <div :ref="`transcriptItemRef${index}`" class="transcript-item-title t-l fs16 strong color333">
                        <span v-if="item.bg > 0">{{item.bg | secondTohhmmss}}</span>
                        <span v-else>00:00:00</span>
                            -
                        <span>{{item.ed | secondTohhmmss}}</span>
                    </div>
                    <div v-if="!searchVal.replace(/\s+/g, '')" :class="['t-l fs12 color666 mt10', currentActive == index ? 'active' : '']">
                        {{item.onebest}}
                    </div>
                    <div v-else class="t-l fs12 color666 mt10" v-html="item.searchContent">
                    </div>
                </div>
            </div>
            <div class="transcript-scroll-top flex y-center" v-show="isShowScrollTop" @click="handleScrollTop">
                <span class="med med-xiangshang2 mr5 fs14"></span>
                <span class="fs14">回到播放位置</span>
            </div>
            <div class="transcript-search-num flex y-center" v-show="isShowSearchNum">
                <span class="med med-xiangshang2 fs14" @click="handleLastEvent"></span>
                <span>{{currentSearchIndex}} / {{searchListIndex.length}}</span>
                <span class="med med-zhankai fs14" @click="handleNextEvent"></span>
            </div>
        </template>
        <a-empty v-else/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { hightLight } from '@/utils/common'
export default {
    name: "transcript-list",
    props: {
        height: {
            default: 314,
            type: Number
        }
    },
    
    data() {
        return {
            currentActive: 0,
            searchVal: '', // 搜索内容
            isStopRoll: false, // 是否停止自动滚动
            timer: null,
            isShowScrollTop: false, // 是否显示当前播放位置按钮
            isShowSearchNum: false, // 是否显示搜索条数
            searchListIndex: [], // 匹配到的内容下标
            currentSearchIndex: 1 // 当前搜索项下标
        }
    },
    computed: {
        ...mapState(['transcriptList'])
    },
    methods: {
        // 上一个
        handleLastEvent () {
            if (this.currentSearchIndex > 1) {
                this.currentSearchIndex-=1
                this.toPositionFun(this.searchListIndex[this.currentSearchIndex - 1])
            }
        },
        // 下一个
        handleNextEvent () {
            if (this.currentSearchIndex < this.searchListIndex.length) {
                this.currentSearchIndex+=1
                this.toPositionFun(this.searchListIndex[this.currentSearchIndex - 1])
            }
        },
        // 跳转倒指定播放位置
        handleToTimeEvent (event, item, index) {
            // 初始化状态，继续滚动
            this.searchListIndex = 1
            this.currentActive = index
            this.searchVal = ''
            this.searchListIndex = []
            this.isShowSearchNum = false
            this.isStopRoll = false
            this.isShowScrollTop = false
            this.$emit('seekVideo', item.bg / 1000)
        },
        // 鼠标滚轮事件
        handleWheelEvent () {
            // 停止自动滚动
            this.isStopRoll = true
            // 显示回到当前播放位置按钮
            this.isShowScrollTop = true
            this.timer && clearTimeout(this.timer)
            if (!this.searchVal.replace(/\s+/g,"")) { // 搜索中不恢复自动滚动
                this.timer = setTimeout(() => { // 5秒后恢复自动滚动
                    this.isStopRoll = false
                    this.isShowScrollTop = false
                }, 5000);
            }
        },
        // 获取当前播放时间
        getCurrentTime (value) {
            if (value >= 0) {
                for (let i = 0; i < this.transcriptList.length; i++) {
                    if ((value >= (this.transcriptList[i].bg / 1000)) && (value < (this.transcriptList[i].ed / 1000))) {
                        this.currentActive = i
                        if (this.$refs[`transcriptItemRef${i}`]) {
                            if (!this.isStopRoll) {
                                this.toPositionFun(i)
                            }
                        }
                        return false
                    }
                }
            }
        },
        // 搜索事件
        handleSearchEvent () {
            if (this.searchVal.replace(/\s+/g,"")) {
                this.searchListIndex = []
                // 停止滚动
                this.isStopRoll = true
                this.timer && clearTimeout(this.timer)
                // 显示下一条
                this.isShowSearchNum = true
                this.transcriptList.forEach((item, index) => {
                    item.searchContent = hightLight(this.searchVal, item.onebest, '#1890ff')
                    if (item.searchContent.indexOf('</span>') != -1) {
                        this.searchListIndex.push(index)
                    }
                })
            } else {
                this.isStopRoll = false
                this.isShowSearchNum = false
                this.isShowScrollTop = false
            }
        },
        // 回到播放位置
        handleScrollTop () {
            this.toPositionFun(this.currentActive)
            // 初始化继续滚动
            this.timer && clearTimeout(this.timer)
            this.isStopRoll = false
            this.isShowScrollTop = false
            this.searchListIndex = 1
            this.searchVal = ''
            this.searchListIndex = []
            this.isShowSearchNum = false
        },
        // 跳转到指定位置
        toPositionFun (index) {
            const transcriptContentRef = this.$refs.transcriptContentRef
            const transcriptItemRef = this.$refs[`transcriptItemRef${index}`][0]
            transcriptContentRef.scrollTo({ top: transcriptItemRef.offsetTop - transcriptContentRef.offsetTop, behavior: 'smooth' });
        },
        // 手指按下
        handleTouchmovetEvent () {
            // 停止自动滚动
            this.isStopRoll = true
            // 显示回到当前播放位置按钮
            this.isShowScrollTop = true
            this.timer && clearTimeout(this.timer)
            if (!this.searchVal.replace(/\s+/g,"")) { // 搜索中不恢复自动滚动
                this.timer = setTimeout(() => { // 5秒后恢复自动滚动
                    this.isStopRoll = false
                    this.isShowScrollTop = false
                }, 5000);
            }
        }
    },
    created(){
    },
};
</script>

<style scoped lang="less">
.transcript-content {
    position: relative;
}
.transcript-scroll-top {
    position: absolute;
    width: 135px;
    bottom: 10px;
    right: 10px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 13px 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    span {
        &:nth-of-type(1) {
            display: inline-block;
            border:1px solid #ffffff;
            width: 22px;
            height: 21px;
            border-radius: 50%;
        }
    }
}
.transcript-search-num {
    position: absolute;
    bottom: 50px;
    right: 10px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 10px 5px 10px;
    width: 135px;
    border-radius: 20px;
    cursor: pointer;
    span {
        &:nth-of-type(1) {
            display: inline-block;
            border:1px solid #ffffff;
            width: 22px;
            height: 21px;
            border-radius: 50%;
        }
        &:nth-of-type(3) {
            display: inline-block;
            border:1px solid #ffffff;
            width: 22px;
            height: 21px;
            border-radius: 50%;
        }
    }
}
.transcript {
    height: 314px;
    overflow-y: scroll;
    transition: all 0.5s linear;
    &::-webkit-scrollbar {
        width: 8px;
        height: 1px;
    }
    &::-webkit-scrollbar-thumb {
        height: 8px;
        border-radius: 6px;
        background: #C5CAD1;
    }
}
.transcript-item {
    cursor: pointer;
    .active {
        color: #1e51c9 !important;
    }
    &-title {
        position: relative;
        padding-left: 15px;
        box-sizing: border-box;
        &:before {
            content: '';
            position: absolute;
            left: -0;
            top: 50%;
            transform: translate(0, -50%);
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #1E51C9;
        }
    }
}
</style>
