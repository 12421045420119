<template>
    <a-modal width="450px" v-model="visible" :footer="null" title="确认购买" centered class="modal-box">
        <div class="flex x-left y-center">
            <span class="color999">商品名称：</span>
            <span class="color000">{{dataInfo.title}}</span>
        </div>
        <div class="flex x-left y-center mt20">
            <span class="color999">商品有效期：</span>
            <!--        validity_type    有效期类型 1短期 2长期-->
            <span class="color000">{{ dataInfo.validity_type == 1 ? dataInfo.validity : '长期有效'}}</span>
        </div>
        <div class="flex x-left y-center mt20">
            <span class="color999">商品价格：</span>
            <span>¥{{dataInfo.price}}</span>
        </div>
        <div class="flex x-left y-center mt20">
            <span class="color999">应付价格：</span>
            <b class="warn-color">¥{{dataInfo.price}}</b>
        </div>
        <a-alert class="mt20" type="error" message="注意：此视频一旦购买，无法退费"></a-alert>
        <div class="mt20 t-c flex flex-column y-center">
            <a-checkbox v-model="checked">我已了解，确认购买</a-checkbox>
            <a-button size="large" @click="handleOkEvent" class="mt20" type="primary" :disabled="!checked">确认支付</a-button>
        </div>
    </a-modal>
</template>

<script>
import isPc from '@/utils/isPC';
export default {
    name: "buyModal",
    components: {},
    props: {

    },
    data() {
        return {
            visible:false,
            checked:false,

            dataInfo:{},
        }
    },
    methods: {
        show(item,type){
            this.visible = true;
            this.dataInfo = item;
            this.__type = type;
        },
        handleOkEvent(){
            // 判断是否登陆
            if(!this.$store.state.userInfo.user_id){
                window.location.replace("https://www.sciconf.cn/unified/login?return_url=" + encodeURIComponent(window.location.href))
                return false;
            }
            let win = isPc() ? window.open('','_blank') : window;
            this.request.get('createOnlineOrder',{type:this.__type,type_id:this.dataInfo.id}).then(res => {
                let { order_no } = res;
                this.request.get('onlinePay',{type:this.__type,order_mark:order_no}).then(res => {
                    //后端返回的数据类似一个form表单，创建一个div后执行表单的submit就OK了
                    const div = win.document.createElement("div");
                    div.id = "payWrapper";
                    div.innerHTML = res;
                    this.$nextTick(() => { 
                        win.document.getElementsByTagName("body")[0].appendChild(div);
                        win.document.querySelector("#payWrapper").children[0].submit(); // 执行后发起支付
                    });
                    this.getOrderQuery(order_no);
                }).catch(err => {
                    alert(err)
                })
                
            }).catch(err => {
                isPc() && win.close()
            })
        },
        // 主动查询
        getOrderQuery(order_no){
            this.$confirm({
                title:"您的订单是否已支付成功?",
                onOk:()=> {
                    this.getOrderMessage(order_no);
                },
                onCancel:()=> {
                    this.getOrderMessage(order_no);
                },
            })
        },

        getOrderMessage(order_mark){
            this.request.get('getOnlineOrderQuery',{order_mark}).then(res => {
                window.location.reload();
            })
        }
    },
    mounted() {
        this.__type = '';
    },
}
</script>

<style lang="less" scoped>
.warn-color{
    color:#FFA45D;
    font-size:16px;
}
.modal-box{
    .color999{
        flex:0 0 100px;
        text-align: right;
    }
}
@media screen and (max-width: 768px) {
    .modal-box {
        /deep/.ant-modal{
            left: 0;
            bottom: 0;
            right:0;
            top: auto;
            width: 100% !important;
            max-width:100%;
            position: absolute;
            padding-bottom: 0;
            margin: 0;
            .ant-modal-body {
                max-height: 70vh;
                overflow-y: scroll;
                padding-bottom:50px;
            }
        }
    }
}
</style>