<template>
    <div class="footer mt30">
       <div class="container flex">
           <div class="footer-text t-l fs12 colorfff">
               <p class="mb5">Copyright©2007-2022 MEDCON. All Rights Reserved.北京美迪康信息咨询有限公司 版权所有.</p>
               <p class="mb5"><a class="colorfff" target="_blank" href="https://beian.miit.gov.cn/">京ICP备11011505号-65</a> 增值电信业务经营许可证：京B2-20201410 网络文化经营许可证：京网文[2020]-1907-290号</p>  
               <p class="mb5">互联网药品信息服务资格证：(京)-非经营性2021-0044</p>
               <p>京公网安备 11011502005438号</p>
           </div>
           <div class="flex">
               <div class="t-c fs12 colorfff mr40">
                   <a-popover title="">
                       <template slot="content">
                           <img style="width:150px;" src="~@/assets/images/footer-qr3.png" alt="小程序码">
                       </template>
                       <img style="width:68px;" src="~@/assets/images/footer-qr3.png" alt="小程序码">
                   </a-popover>
                   <p style="margin-bottom:0" class="mt10">会务通参会助手</p>
               </div>
               <div class="t-c fs12 colorfff mr40">
                   <a-popover title="">
                       <template slot="content">
                           <img style="width:150px;" src="~@/assets/images/footer-qr1.png" alt="二维码">
                       </template>
                       <img style="width:68px;" src="~@/assets/images/footer-qr1.png" alt="二维码">
                   </a-popover>
                    <p style="margin-bottom:0" class="mt10">会务通订阅号</p>
               </div>
               <div class="t-c fs12 colorfff mr40">
                   <a-popover title="">
                       <template slot="content">
                           <img style="width:150px;" src="~@/assets/images/footer-qr2.png" alt="二维码">
                       </template>
                       <img style="width:68px;" src="~@/assets/images/footer-qr2.png" alt="二维码">
                   </a-popover>
                   <p style="margin-bottom:0" class="mt10">会务通服务号</p>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
export default {
    name: 'layoutFooter',
    components: {
       
    },
    mounted() {

    },
}
</script>
<style lang="less" scoped>
.footer{
    background: #333;
    padding:20px 0;
    &-text{

    }
}
@media screen and (max-width:768px){
    .container{
        display: block;
        padding: 0 10px;
    }
}
</style>