<!-- 推荐大咖 -->
<template>
    <div class="recommend-item mb15" @click="expertJumpDetail(item.user_id,item.id,'special-ma',$route.params.id)">
        <div class="flex x-between">
            <img class="recommend-item-avatar" :src="item.avatar || require('@/assets/images/person-default.png')" />
            <div style="width:calc(100% - 70px);">
                <div class="flex x-left y-center">
                    <div class="flex-column  ml15" style="width:calc(100% - 70px);">
                        <div class="t-l row-1">
                            <span class="strong fs16 color333">{{ item.real_name }}</span>
                            <span class="ml10 color999 fs12">{{ item.department }}</span>
                        </div>
                        <div class="t-l mt5 color666 fs12 row-1">{{ item.org_cnname }}</div>
                    </div>
                    <div class="attention-btn flex x-center y-center cursor" v-if="item.fans_status == 0">
                        <i class="med med-jiahao"></i>
                        <span class="fs12 strong colorfff ml5">关注</span>
                    </div>
                    <div class="attention-btn cancel-btn flex x-center y-center cursor" v-else>
                        <span class="fs12 strong color999">取消关注</span>
                    </div>
                </div>
                <div class="ml15 mt10 row-2" v-if="item.resume" v-html="item.resume"></div>
            </div>
            
            <!-- <div class="isattention-btn flex x-center y-center cursor" v-if="videoDetailList.fans_status == 1">
                <span class="fs12 strong color999 ml5">已关注</span>
            </div> -->
        </div>
    </div>
</template>

<script>
import { expertJumpDetail } from '@/utils/jumpPageMethods'
export default {
    name: "recommend-expert-item",
    props: {
        index: {
            type: String,
            default: '',
        },
        item: {
            type: Object,
            defalut: () => {
                //   name:1,
                //   is_attention:'',
            },
        },
    },
    data() {
        return {

        }
    },
    methods: {
        expertJumpDetail
    }
}
</script>

<style lang="less" scoped>
.recommend-item {
    cursor: pointer;
    &-avatar {
        width: 70px;
        height: 100px;
        // border-radius: 50%;
        object-fit: cover;
        object-position: top;
        background: rgb(192,201,223);
        
    }
    .attention-btn {
        width: 64px;
        height: 34px;
        background: linear-gradient(to right, #5f96e6, #1e51c9);
        border-radius: 5px;

        .med-jiahao::before {
            font-size: 10px;
            color: #ffffff;
        }
    }
    .cancel-btn{
        background: #eff3fb;
    }
}
</style>