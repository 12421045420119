<template>
    <div class="component-box">
        <div class="course-block flex x-left y-center flex-wrap">
            <div :style="{ width: width1 }" :class="['course-item', { 'mr0': (index + 1) % lineCount == 0 }]"
                v-for="(item,index) in dataList" :key="index" @click="handleNavigateEvent(item,type)">
                <div class="publicity-picture" :style="{ height:(parseInt(width1)/16*9+'px') }">
                    <img class="bg-img" :src="item.img | urlFilter(360)" alt="" @load="handleLoadEvent($event,index)" @error="handleErrorEvent(index)">
                    <img class="bg-img-top" :src="item.img | urlFilter(360)" alt="">
                    <div class="section-wrapper flex x-left">
                        <div class="section-count" v-if="item.courseNum">
                            {{ item.courseNum }}
                        </div>
                        <!-- <div class="section-status" v-if="item.power_type == 1">免费</div> -->
                    </div>
                </div>
                <div class="course-info">
                    <div class="title row-2 strong">{{ item.title }}</div>
                    <div class="flex source x-between mt10" v-if="item.author || item.author_org || item.real_name">
                        <div class="strong" v-if="(item.author || item.real_name) && !item.price ">{{ item.author || item.real_name }}</div>
                        <div class="flex-item ml15 row-1" v-if="item.author_org">{{ item.author_org }}</div>
                    </div>
                    <div class="other flex x-left y-bottom" v-if="item.hits || item.price">
                        <div class="study-num" v-if="item.hits">{{ item.hits }}人已观看</div>
                        <div class="money flex y-center strong x-left" v-if="parseFloat(item.price)"><span v-if="item.price != '免费'">¥</span>{{ item.price }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { committee_id } from '@/config';
export default {
    name: "courser-list-item",
    props: {
        dataList: {
            type: Array,
            default: () => []
        },
        lineCount: {
            type: [Number, String],
            default: 3
        },//几个一行，最后一个需要设置marginRight 0
        showDetail: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: '243px'
        },
        height: {
            type: String,
            default: '137px'
        },
        // 如果targetType == 1抛出点击事件
        targetType: {
            type: [String, Number],
            default: 0,
        },
        type:{
            type: String,
            default:''
        }, //type为ma 综合专题相关页面  // type为course 精品课堂视频详情页
    },
    data(){
        return {
            width1:'',
        }
    },
    methods: {
        handleNavigateEvent(item,type) {
            // 视频
            let id = item.id || '';
            if (id && this.targetType != 1) {
                // window.open('https://www.sciconf.cn/online/video/play/' + committee_id + '?vid=' + id, '_blank')
                // type=='ma'为综合专题相关页面
                let path = ''
                if (type == 'ma') {
                    path = '/special-ma/video-detail/'+this.$route.params.id+'?id=' + id
                } else {
                    path = '/video-list-detail?id=' + id
                }
                this.$router.push({
                    path: path
                })
            } else {
                this.$emit('clickEvent', item);
            }
        },
        handleLoadEvent(e,index) {

            let that = this
            let img = new Image()
            if(e.path){
                img.src = e.path[0].src
            }
            img.onload = function () {
                let imgWidth = img.width
                let imgHeight = img.height
                if(imgWidth < 30 || imgHeight < 30){
                    that.dataList[index].img = 'https://files.sciconf.cn/medcon/20180719/a.jpg'
                }
            }
            if(img.src == 'https://files.sciconf.cn/mpweixin/dc1bd080-23e7-11eb-8a36-ebb87efcf8c0.jpg!wx360'){
                that.dataList[index].img = 'https://files.sciconf.cn/medcon/20180719/a.jpg'
            }
        },
        handleErrorEvent(index){
            this.dataList[index].img = 'https://files.sciconf.cn/medcon/20180719/a.jpg'
        }
    },
    mounted(){
        console.log(this.dataList)
        // 取屏幕宽度的1/3设置移动端图片宽度
        this.width1 = this.width
        let clientWidth = document.body.clientWidth
        if(clientWidth <= 768 && clientWidth >= 550){
            this.width1 = (clientWidth - 60)/3 + 'px'
        }else if(clientWidth < 550){
            this.width1 = (clientWidth - 40)/2 + 'px'
        }
    },
}
</script>

<style scoped lang="less">
.component-box {
    .course-block {
        .course-item {
            width: 243px;
            text-align: left;
            cursor: pointer;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 20px;
            margin-bottom: 20px;

            &.mr0 {
                margin-right: 0;
            }

            .publicity-picture {
                width: 100%;
                height: 137px;
                position: relative;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    transition: all .6s;
                }

                &:hover img {
                    transform: scale(1.2);
                }
                .bg-img{
                    filter: blur(3px) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxmaWx0ZXIgaWQ9ImZpbHRlci1wcmltaXRpdmVzIj48ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIzIiAvPjxmZUNvbG9yTWF0cml4IHR5cGU9Imh1ZVJvdGF0ZSIgdmFsdWVzPSIyNzAiLz48ZmVDb2xvck1hdHJpeCB0eXBlPSJzYXR1cmF0ZSIgdmFsdWVzPSIwLjc1Ii8+PGZlQmxlbmQgbW9kZT0ibXVsdGlwbHkiLz48L2ZpbHRlcj48L2RlZnM+PC9zdmc+DQo=);
                }
                .bg-img-top{
                    height: 100%;
                    width: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
                .section-wrapper{
                    position: absolute;
                    bottom: 2px;
                    left: 3px;
                }
                .section-count {
                    height: 22px;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 12px;
                    line-height: 22px;
                    border-radius: 22px;
                    padding:0 10px;
                    background: linear-gradient(to right,#f9be68,#fdad61);
                }
                .section-status{
                    margin-left:5px;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: 12px;
                    line-height: 22px;
                    padding:0 10px;
                    border-radius: 22px;
                    background: #f66;
                }
            }

            .course-info {
                background: #F9F9F9;
                height: 122px;
                padding: 10px;
                box-sizing: border-box;

                .title {
                    color: @title;
                    height: 45px;
                }

                .source {
                    color: #666666;
                    font-size: 14px;
                }

                .other {
                    .study-num {
                        color: @desc;
                        font-size: 12px;
                    }

                    .money {
                        margin-left: auto;
                        font-size: 18px;
                        color: #FFA45D;
                        position: relative;
                        top: 2px;

                        span {
                            font-size: 12px;
                            position: relative;
                            top: 2px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:768px){
    .course-block{
        justify-content: space-between;
        .course-item{
            margin-right: 0!important;
        }
    }
}
</style>
