<template>
    <div class="comment-box">
        <!-- <textarea class="comment-input pt5 pb5 pl10 pr10" type="textarea" /> -->
        <a-textarea
            class="comment-input pt5 pb5 pl10 pr10"
            placeholder="我来说两句"
            v-model="value"
        />
        <div class="comment-text colorfff strong fs14 mt10" @click="handleTextChange()">发表</div>
        <!-- 评论列表 -->
        <div class="comment-list" v-if="commentCount > 0">
            <div v-for="(item,index) in list" :key="index" class="comment-item pt15 pb15 flex x-left y-top">
                <img v-if="item.avatar" class="comment-item-img" :src="item.avatar | urlFilter(250)" alt="">
                <img v-else class="comment-item-img" :src="require('@/assets/images/person-default.png')" alt="">
                <div class="ml15 flex-item">
                    <p class="t-l">
                        <span class="s16 strong color333">{{item.real_name_1 || item.real_name_2 || item.real_name || '匿名'}}</span>
                        <span class="ml10 fs12 color999">{{item.update_time}}</span>
                        <!-- <span class="ml15">
                            <i class="med med-50jubao"></i>
                            <span class="ml5 fs12 color999">举报</span>
                        </span> -->
                    </p>
                    <p class="mt5 t-l fs14 color333" v-html="item.content"></p>
                </div>
            </div>
            <div class="t-c mt20" v-if="commentCount > 0">
                <a-pagination :total="commentCounts" v-model:current="commentPage" :page-size="pageSize" show-quick-jumper :hideOnSinglePage="true" @change="handlePageChangeEvent"  />
            </div>
        </div>
        <div class="comment-list" v-else>
            <a-empty description="暂无评论" />
        </div>
        
    </div>
</template>

<script>
export default {
    name: "comment-list",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        commentCount:{
            type:Number,
            default:6
        },
        pageSize:{
            type:Number,
            default:5
        },
    },
    watch: {
        commentCount(newVal) {
            this.commentCounts = newVal
        },
        immediate: true,
        deep:true,
    },
    data() {
        return {
            selectIndex: 0,
            current:1,
            commentPage:1,
            commentCounts:6,
            value:'',
        }
    },
    methods: {
        handlePageChangeEvent(key){
            console.log(key)
            window.scrollTo(0,0);
            this.commentPage = key
            this.$emit('pageEvent',key)
        },
        handleTextChange(){
            console.log(this.value)
            this.$emit('handleTextChange',this.value)
            this.value = ''
        }
    },
    mounted() {
        console.log(this.list)
        this.commentCounts = this.commentCount
    }
};
</script>

<style scoped lang="less">
.comment-box{
    width: 100%;
    .comment-input{
        width: 100%;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 10px;
        outline-color: #cccccc;
    }
    .comment-text{
        width: 70px;
        height: 34px;
        background: linear-gradient(to right,#5F96E6,#1E51C9);
        border-radius: 5px;
        box-shadow: 0 1px 2px 0 rgba(30, 81, 201, 0.1);
        float: right;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
    }
    .comment-list{
        width: 100%;
        margin-top: 58px;
        .comment-item{
            border-top: 1px solid #f1f1f1;
            &-img{
                width: 45px;
                height: 45px;
                border-radius: 50%;
                object-fit: cover;
            }
            p{
                margin-bottom: 0;
            }
            .med-50jubao:before{
                font-size: 12px;
            }
        }
    }
}
</style>
